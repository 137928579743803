














































import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomMoney,
  AtomModal,
  MolCollapseIndicator,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { InventorySnoozeSwitcher } from "../snooze-switcher";
import { InventorySnoozeItem } from "../snooze-item";
import { inventoryRepo } from "../../repo";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("inventory-item");

export default defineComponent({
  name: "InventoryItem",
  components: {
    AtomText,
    MolCollapseIndicator,
    AtomMoney,
    AtomModal,
    InventorySnoozeSwitcher,
    InventorySnoozeItem,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    hasModifiers: {
      type: Boolean,
      default: false,
    },
    isModifiersOpen: {
      type: Boolean,
      default: false,
    },
    isModifier: {
      type: Boolean,
      default: false,
    },
    isSnoozed: {
      type: Boolean,
      required: true,
    },
    snoozedUntil: {
      type: Date,
      default: undefined,
    },
    onToggleItems: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    onChange: {
      type: Function as PropType<
        (itemId: string, isSnoozed: boolean, snoozedUntil?: Date) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const nameTextType = computed(() => {
      return props.isModifier
        ? AtomTextTypeEnum.BODY
        : AtomTextTypeEnum.BODY_BOLD;
    });

    const isSnoozeItemModalVisible = ref(false);

    function closeSnoozeItemModal() {
      isSnoozeItemModalVisible.value = false;
    }

    const isSnoozing = ref(false);
    async function onSnoozeItem(datetime?: Date) {
      isSnoozing.value = true;

      try {
        await inventoryRepo.snoozeItem({
          businessId: props.businessId,
          outletId: props.outletId,
          itemId: props.id,
          startsAt: new Date(),
          endsAt: datetime ?? undefined,
        });

        props.onChange(props.id, true, datetime);
      } catch (e: any) {
        generalErrorToast();
        report(e);
      } finally {
        isSnoozing.value = false;
        closeSnoozeItemModal();
      }
    }

    async function onUnsnoozeItem() {
      try {
        await inventoryRepo.unsnoozeItem({
          businessId: props.businessId,
          outletId: props.outletId,
          itemId: props.id,
        });

        props.onChange(props.id, false);
      } catch (e: any) {
        generalErrorToast();
        report(e);
      }
    }

    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      nameTextType,
      isSnoozing,
      onSnoozeItem,
      closeSnoozeItemModal,
      isSnoozeItemModalVisible,
      onUnsnoozeItem,
    };
  },
});
