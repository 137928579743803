
































































































import { t, tc } from "@/i18n";
import {
  AtomText,
  AtomRadio,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import dayjs from "dayjs";

const css = bemBuilder("inventory-snooze-item");

export default defineComponent({
  name: "InventorySnoozeItem",
  components: {
    AtomRadio,
    AtomText,
    AtomButton,
  },
  props: {
    itemName: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(datetime?: Date) => void>,
      required: true,
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const hoursAmount = ref("6");

    const requestUpdate = () => {
      if (hoursAmount.value === "0") {
        props.onChange(undefined);
        return;
      }

      const pauseUntil = dayjs().add(parseInt(hoursAmount.value, 10), "hours");
      props.onChange(pauseUntil.toDate());
    };

    return {
      t,
      tc,
      css,
      hoursAmount,
      requestUpdate,
      AtomTextTypeEnum,
      AtomTextTagEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
