

















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomIcon,
  AtomText,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import dayjs from "dayjs";

const css = bemBuilder("inventory-snooze-switcher");

export default defineComponent({
  name: "InventorySnoozeSwitcher",
  components: {
    AtomButton,
    AtomIcon,
    AtomText,
  },
  props: {
    isSnoozed: {
      type: Boolean,
      required: true,
    },
    snoozedUntil: {
      type: Date,
      default: undefined,
    },
    onResume: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onPause: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const onClick = () => {
      if (!props.isSnoozed) {
        props.onPause();
        return;
      }

      props.onResume();
    };

    const formattedDate = computed(() => {
      if (!props.snoozedUntil) return "";

      return dayjs(props.snoozedUntil).format("MMM DD, YYYY - h:mm a");
    });

    return {
      css,
      t,
      onClick,
      formattedDate,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
