import {
  IUnsnoozeItemFromOutletBodyPayload,
  IUnsnoozeItemFromOutletRequest,
} from "./types";
import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";

export class UnsnoozeItemFromOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: unsnoozeItemFromOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function unsnoozeItemFromOutlet(
  request: IUnsnoozeItemFromOutletRequest
): Promise<void> {
  const payload: IUnsnoozeItemFromOutletBodyPayload = {
    outlet_id: request.outletId,
  };

  try {
    await http.post(
      `/api/v3/businesses/${request.businessId}/items/${request.itemId}/unsnooze`,
      { ...payload }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UnsnoozeItemFromOutletError(error);
  }
}
