import {
  ISnoozeItemFromOutletRequest,
  ISnoozeItemFromOutletBodyPayload,
} from "./types";
import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";

export class SnoozeItemFromOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: snoozeItemFromOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function snoozeItemFromOutlet(
  request: ISnoozeItemFromOutletRequest
): Promise<void> {
  const payload: ISnoozeItemFromOutletBodyPayload = {
    outlet_id: request.outletId,
    starts_at: request.startsAt.toJSON(),
    ends_at: request.endsAt ? request.endsAt.toJSON() : null,
  };

  try {
    await http.post(
      `/api/v3/businesses/${request.businessId}/items/${request.itemId}/snooze`,
      { ...payload }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new SnoozeItemFromOutletError(error);
  }
}
