import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "@/v2/repo/http";
import { IInventoryItem, IInventorySnooze } from "../../domain/inventary";
import {
  IFetchInventoryFromOutletRequest,
  IFetchInventoryFromOutletResponse,
  IFetchInventoryFromOutletContract,
  IFetchInventoryFromOutletBodyParams,
  IItem,
  ISnoozed,
} from "./types";

export class FetchInventoryFromOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: fetchInventoryFromOutlet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchInventoryFromOutlet(
  request: IFetchInventoryFromOutletRequest
): Promise<IFetchInventoryFromOutletResponse> {
  let apiResponse: AxiosResponse<IFetchInventoryFromOutletContract>;

  const params: IFetchInventoryFromOutletBodyParams = {
    include: "modifiers.items.snoozed,snoozed",
    limit: 10,
    sort: "name",
    is_modifier: "0",
    name: request.searchNameQuery ?? "",
    page: request.page ?? 1,
  };

  try {
    apiResponse = await http.get<IFetchInventoryFromOutletContract>(
      `/api/v4/businesses/${request.businessId}/items`,
      { params }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchInventoryFromOutletError(error);
  }

  return {
    data: apiResponse.data.data.map((item) => {
      return sanitizeItem(item, request.outletId);
    }),
    pagination: {
      currentPage: apiResponse.data.meta.current_page,
      lastPage: apiResponse.data.meta.last_page,
      perPage: apiResponse.data.meta.per_page,
      total: apiResponse.data.meta.total,
    },
  };
}

function getSnoozeForOutlet(
  snoozes: Array<ISnoozed>,
  outletId: string
): IInventorySnooze | undefined {
  const snoozeRecord = snoozes.find((obj) => obj.outlet_id === outletId);

  return !snoozeRecord
    ? undefined
    : {
        startsAt: new Date(snoozeRecord.starts_at),
        endsAt: snoozeRecord.ends_at
          ? new Date(snoozeRecord.ends_at)
          : undefined,
      };
}

function sanitizeItem(item: IItem, outletId: string): IInventoryItem {
  const modifierLists = Array.isArray(item.modifier_lists)
    ? item.modifier_lists
    : [];

  return {
    name: item.name,
    id: item.id,
    amount: item.amount,
    modifiers: modifierLists
      .map((obj) => obj.modifiers)
      .flat()
      .map((o) => sanitizeItem(o, outletId)),
    snooze: getSnoozeForOutlet(item.snoozed, outletId),
  };
}
