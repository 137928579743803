












































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { InventoryItem } from "../item";
import { IInventoryItem } from "../../domain/inventary";

const css = bemBuilder("inventory-item-line");

export default defineComponent({
  name: "InventoryItemLine",
  components: {
    InventoryItem,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    modifiers: {
      type: Array as PropType<IInventoryItem["modifiers"]>,
      required: true,
    },
    isModifier: {
      type: Boolean,
      default: false,
    },
    isSnoozed: {
      type: Boolean,
      default: false,
    },
    snoozedUntil: {
      type: Date,
      default: undefined,
    },
    onItemChange: {
      type: Function as PropType<
        (itemId: string, isSnoozed: boolean, snoozedUntil?: Date) => void
      >,
      required: true,
    },
  },
  setup() {
    const isModifiersOpen = ref(false);

    function onToggleItems() {
      isModifiersOpen.value = !isModifiersOpen.value;
    }

    return {
      css,
      isModifiersOpen,
      onToggleItems,
    };
  },
});
