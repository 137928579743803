


























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, watch } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomLoading,
  MolSearchBox,
  MolPagination,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { OutletSwitcher } from "@/v2/components/outlet-switcher";
import { InventoryItemLine } from "../../component/item-line";
import { IInventoryItem } from "../../domain/inventary";
import { inventoryRepo } from "../../repo";
import { IFetchInventoryFromOutletResponse } from "../../repo/fetch-inventory-from-outlet/types";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { useHelpers } from "@/v2/composable/use-helpers";
import { useDebounceValue } from "@/composable";
import { t, tc } from "@/i18n";

const css = bemBuilder("inventory-main");

export default defineComponent({
  name: "InventoryMain",
  components: {
    AtomText,
    AtomLoading,
    MolSearchBox,
    MolPagination,
    OrgHeaderInfo,
    OutletSwitcher,
    InventoryItemLine,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const searchQuery = ref("");
    const { currency, language: locale } = useHelpers().getCurrentBusiness(
      props.businessId
    );

    const { debouncedValue } = useDebounceValue(searchQuery, 500);
    watch(debouncedValue, () => fetchItems(1, true));

    const items = ref<IInventoryItem[]>([]);
    const pagination = ref<IFetchInventoryFromOutletResponse["pagination"]>({
      currentPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    });

    async function fetchItems(page: number, withLoading: boolean) {
      if (withLoading) {
        isLoading.value = true;
      }

      try {
        const response = await inventoryRepo.get({
          businessId: props.businessId,
          outletId: props.outletId,
          page,
          searchNameQuery: searchQuery.value,
        });
        items.value = response.data;
        pagination.value = response.pagination;
      } catch (e: any) {
        generalErrorToast();
        report(e);
      } finally {
        isLoading.value = false;
      }
    }

    watch(
      () => props.outletId,
      () => fetchItems(1, true),
      { immediate: true }
    );

    async function onItemChange() {
      await fetchItems(pagination.value.currentPage, false);
    }

    function getEndAt(item: IInventoryItem) {
      return item?.snooze?.endsAt;
    }

    return {
      css,
      t,
      tc,
      items,
      onItemChange,
      isLoading,
      searchQuery,
      fetchItems,
      pagination,
      currency,
      getEndAt,
      locale,
      AtomTextTypeEnum,
    };
  },
});
